import { formatTimestamp } from '../../../utils/dateTime';

// Utility function to safely get values, allowing 0 as a valid value
function getValueOrDefault(value, defaultValue = '') {
  return value === undefined || value === null ? defaultValue : value;
}

// Use this function to map data
export function mapMarketDataUpdate(data) {
  const transformedData = {
    symbol: getValueOrDefault(data.contract_id),
    last: getValueOrDefault(data.last?.p),
    lastSize: getValueOrDefault(data.last?.v),
    volume: getValueOrDefault(data.vol),
    bid: getValueOrDefault(data.bid?.p),
    bidSize: getValueOrDefault(data.bid?.v),
    ask: getValueOrDefault(data.offer?.p),
    askSize: getValueOrDefault(data.offer?.v),
    change: '',
    changePercent: '',
    open: getValueOrDefault(data.o),
    high: getValueOrDefault(data.h),
    low: getValueOrDefault(data.l),
    prevClose: '',
    expiryDate: '',
    description: '',
    lastUpdate: formatTimestamp(getValueOrDefault(data.timestamp)),
    symbolStatus: getValueOrDefault(data.status),
    prevSettlementPrice: getValueOrDefault(data.pre_sett),
  };

  return transformedData;
}
