// Helper for subscribing to market data
export const subscribeToMarketDataHelper = (webSocketService, contracts) => {
  const subscriptionMessage = {
    event: 'user:md_price:subscribe',
    data: { contract_id: contracts },
    token: webSocketService.token,
  };

  // Send the subscription message to WebSocket for batch contracts
  webSocketService.send(JSON.stringify(subscriptionMessage));
};

// Helper for unsubscribing from market data
export const unsubscribeFromMarketDataHelper = (
  webSocketService,
  contracts
) => {
  const unsubscriptionMessage = {
    event: 'user:md_price:unsubscribe',
    data: { contract_id: contracts },
    token: webSocketService.token,
  };

  // Send the unsubscription message to WebSocket for batch contracts
  webSocketService.send(JSON.stringify(unsubscriptionMessage));
};
