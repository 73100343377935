const OrderTypes = ['pbOrderInfo', 'pbStrategyOrderInfo']; // Array of order types

export const subscribeToOrders = (webSocketService, onOrdersUpdate) => {
  const subscriptionMessage = {
    event: 'user:trade_order:subscribe',
    data: [],
    token: webSocketService.token,
  };

  // Register a listener for order updates
  const orderListener = (data) => {
    if (OrderTypes.includes(data.type)) {
      // Check if data.type is in OrderTypes array
      onOrdersUpdate(data.result);
    }
  };

  // Register listeners for all order types in the array
  OrderTypes.forEach((orderType) => {
    webSocketService.addListener(orderType, orderListener);
  });

  // Send subscription message
  webSocketService.send(JSON.stringify(subscriptionMessage));

  // Return a function to unsubscribe, making it easy to clean up
  return () => {
    unsubscribeFromOrders(webSocketService, orderListener);
  };
};

export const unsubscribeFromOrders = (webSocketService, orderListener) => {
  const unsubscriptionMessage = {
    event: 'user:trade_order:unsubscribe',
    data: [],
    token: webSocketService.token,
  };

  // Send unsubscription message
  webSocketService.send(JSON.stringify(unsubscriptionMessage));

  // Remove listeners for all order types in the array
  OrderTypes.forEach((orderType) => {
    webSocketService.removeListener(orderType, orderListener);
  });
};
