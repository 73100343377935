import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import Dropdown from '../dropdown/dropdownComponent';
import { useMetadata } from '../../contexts/MetadataContext';
import { greaterThanZero, validateNumber } from '../../utils/numbers';
import { useTable } from '../../contexts/TableContext';
import { useWorkspaces } from '../../contexts/WorkspacesContext';
import { mapMarketDataUpdate } from '../../services/websocket/marketdata/MarketDataMapper';
import CustomGrid from '../grids/CustomGrid';
import { tradeInfoFields } from './orderPanelCol';
import {
  addOrder,
  amendOrder,
} from '../../services/orderActions/orderActionsService';
import { useWebSocket } from '../../contexts/WebSocketContext';
import 'react-toastify/dist/ReactToastify.css'; // Import the toastify CSS
import { useMarketData } from '../../contexts/MktDataContext';
import { cancelOrders } from '../../services/orderActions/orderActionsService';
import OrderDialog from '../orderActions/OrderDialog';

const timeInForceList = ['Day', 'GTC', 'GTD'];
const limitIds = ['1', '4', '7'];
const stopIds = ['2', '5', '8'];
const stopLimitIds = ['3', '6', '9'];

function OrderEntryPanel({
  actionType = '',
  orderInfo = null,
  toConfirm = false,
}) {
  const [symbol, setSymbol] = useState({
    value: orderInfo
      ? `${orderInfo.exchange_name}\\${orderInfo.contract_name}\\${orderInfo.contract_date}`
      : '',
    error: false,
    helperText: '',
  });
  const [timeInForce, setTimeInForce] = useState({
    value: orderInfo?.timeInForce || 'Day',
    error: false,
    helperText: '',
  });
  const [quantity, setQuantity] = useState({
    value: orderInfo ? orderInfo.lots - (orderInfo.amount_filled || 0) : '1',
    error: false,
    helperText: '',
  });
  const [comment, setComment] = useState(orderInfo?.remarks || '');
  const [stopLoss, setStopLoss] = useState(orderInfo?.stopLoss || true);
  const [takeProfit, setTakeProfit] = useState(orderInfo?.takeProfit || true);
  const [selectedAccount, setSelectedAccount] = useState({
    value: orderInfo?.trader_account || '',
    error: false,
    helperText: '',
  });
  const [selectedOrderType, setSelectedOrderType] = useState({
    value: String(orderInfo?.order_type || '1'),
    error: false,
    helperText: '',
  });

  const [openClose, setOpenClose] = useState({
    value: String(orderInfo?.open_close || '3'),
    error: false,
    helperText: '',
  });

  const [hedgeFlag, setHedgeFlag] = useState({
    value: String(orderInfo?.hedge_flag || '0'),
    error: false,
    helperText: '',
  });

  const [limitPrice, setLimitPrice] = useState({
    value: String(orderInfo?.price_1 || orderInfo?.price1 || ''),
    error: false,
    helperText: '',
  });

  const [stopPrice, setStopPrice] = useState({
    value: String(orderInfo?.price_2 || orderInfo?.price2 || ''),
    error: false,
    helperText: '',
  });

  const [clientOrderId, setClientOrderId] = useState({
    value: String(orderInfo?.client_order_id || ''),
    error: false,
    helperText: '',
  });

  const [exchangeOrderId, setExchangeOrderId] = useState({
    value: String(orderInfo?.order_id || ''),
    error: false,
    helperText: '',
  });

  const orderSideRef = useRef('');

  const hasUpdatedLimitPrice = useRef(false);

  const theme = useTheme();

  const { currentUser, token, loginId } = useAuth();
  const { enumList } = useMetadata();
  const { selectedRow } = useTable();
  const { currentWorkspace } = useWorkspaces();
  const { webSocketInstance } = useWebSocket();
  const [orderStatus, setOrderStatus] = useState({});
  const { marketData, subscribeToMarketData } = useMarketData();
  const priceInfoRef = useRef({});
  const [isOpen, setIsOpen] = useState(false);
  const [newActionType, setNewActionType] = useState('');
  const titleRef = useRef('');
  const toOrderRef = useRef(null);

  // Determine the `isDisabled` flag based on actionType
  const isDisabled = actionType === 'Delete';
  const isAmend = actionType === 'Amend';
  const isNew = actionType === 'New';

  const accs = useMemo(() => {
    const acList = currentUser.user_details.trader_accs;
    if (acList.length > 0 && !selectedAccount.value) {
      setSelectedAccount({
        value: acList[0],
        error: false,
        helperText: '',
      });
    }

    return acList || []; // Replace with actual computation
  }, [currentUser]);

  useEffect(() => {
    if (selectedRow && selectedRow[currentWorkspace]) {
      if (selectedRow[currentWorkspace]?.symbol) {
        setSymbol((prevSymbol) => ({
          ...prevSymbol, // Preserve the existing state
          value: selectedRow[currentWorkspace]?.symbol || '', // Update only the value field
          error: false, // Reset the error to false
          helperText: '', // Clear the helperText
        }));
        hasUpdatedLimitPrice.current = false; // Reset the flag if the order type is not in limitIds
      }
    }
  }, [selectedRow, currentWorkspace]);

  const processedEnums = useMemo(() => {
    const processEnum = (enumObj) => {
      if (!enumObj) {
        return [];
      }
      return Object.entries(enumObj).map(([key, value]) => ({
        value: key,
        label: value,
      }));
    };

    return {
      orderTypes: processEnum(enumList?.order_type),
      hedgeFlags: processEnum(enumList?.hedge_flag),
      openCloseTypes: processEnum(enumList?.open_close),
    };
  }, [enumList]);

  const handleChanges = (event, setValue, validate) => {
    const value = event.target.value;
    if (validate) {
      const { isValid, helperText } = validate(value);
      console.log(value);
      setValue({ value, error: !isValid, helperText });
    } else {
      setValue({ value, error: false, helperText: '' });
    }
  };

  const contracts = useMemo(() => {
    return symbol.value ? [symbol.value] : []; // Pass an empty array if symbol is empty
  }, [symbol]);

  useEffect(() => {
    if (contracts.length > 0) {
      subscribeToMarketData(contracts, mapMarketDataUpdate);
    }
  }, [contracts]);

  useEffect(() => {
    const mktPrice = Object.values(marketData).filter((data) =>
      contracts.includes(data.symbol)
    );

    if (mktPrice.length > 0) {
      const firstRow = mktPrice[0];

      // Update the ref with new market data
      priceInfoRef.current = {
        ...priceInfoRef.current, // Keep any existing data intact
        ...firstRow, // Update with the new data from the market
        lastInfo:
          firstRow.lastSize !== null &&
          firstRow.lastSize !== undefined &&
          firstRow.lastSize !== ''
            ? `${firstRow.last} x ${firstRow.lastSize}`
            : firstRow.last,
        bidInfo:
          firstRow.bidSize !== null &&
          firstRow.bidSize !== undefined &&
          firstRow.bidSize !== ''
            ? `${firstRow.bid} x ${firstRow.bidSize}`
            : firstRow.bid,
        askInfo:
          firstRow.askSize !== null &&
          firstRow.askSize !== undefined &&
          firstRow.askSize !== ''
            ? `${firstRow.ask} x ${firstRow.askSize}`
            : firstRow.ask,
      };
    }
    // Don't update if there's no valid market data
  }, [marketData, contracts]);

  useEffect(() => {
    const isPriceInfoValid =
      priceInfoRef.current && Object.keys(priceInfoRef.current).length > 0;
    if (
      !hasUpdatedLimitPrice.current && // Check if limitPrice has not been updated yet
      isPriceInfoValid &&
      !(isAmend || isDisabled || isNew) // Check if priceInfo is not null or undefined
    ) {
      setLimitPrice((prev) => ({
        ...prev,
        value: priceInfoRef.current.last, // Set this to the desired default value
        error: false,
        helperText: '',
      }));
      setStopPrice((prev) => ({
        ...prev,
        value: priceInfoRef.current.last, // Set this to the desired default value
        error: false,
        helperText: '',
      }));
      hasUpdatedLimitPrice.current = true; // Mark as updated
    }
  }, [priceInfoRef.current]);

  useEffect(() => {
    hasUpdatedLimitPrice.current = false; // Reset the flag if the order type is not in limitIds
  }, [selectedOrderType]);

  // Generate a unique client_order_id
  const generateClientOrderId = () => {
    return `${loginId}|${selectedAccount.value}|${Date.now()}`;
  };

  // Validate if symbol is empty
  const validateSymbol = () => {
    if (!symbol.value) {
      setSymbol((prevSymbol) => ({
        ...prevSymbol,
        error: true,
        helperText: 'Symbol is required', // Display error message in helperText
      }));
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  // Validate limit price for limit and stop limit orders
  const validateLimitPrice = () => {
    if (limitIds.includes(selectedOrderType.value) && !limitPrice.value) {
      setLimitPrice((prevPrice) => ({
        ...prevPrice,
        error: true,
        helperText: 'Limit price is required for limit or stop limit orders',
      }));
      return false;
    }
    return true;
  };

  // Validate stop price for stop and stop limit orders
  const validateStopPrice = () => {
    if (stopIds.includes(selectedOrderType.value) && !stopPrice.value) {
      setStopPrice((prevPrice) => ({
        ...prevPrice,
        error: true,
        helperText: 'Stop price is required for stop or stop limit orders',
      }));
      return false;
    }
    return true;
  };

  // Validate both limit price and stop price for stop limit orders
  const validateStopLimitOrder = () => {
    if (stopLimitIds.includes(selectedOrderType.value)) {
      if (!limitPrice.value || !stopPrice.value) {
        setLimitPrice((prevPrice) => ({
          ...prevPrice,
          error: !limitPrice.value,
          helperText: limitPrice.value ? '' : 'Limit price is required',
        }));
        setStopPrice((prevPrice) => ({
          ...prevPrice,
          error: !stopPrice.value,
          helperText: stopPrice.value ? '' : 'Stop price is required',
        }));
        return false;
      }

      // Validate stop price and limit price relationship for stop limit orders
      if (
        orderSideRef.current === '1' &&
        parseFloat(stopPrice.value) <= parseFloat(limitPrice.value)
      ) {
        setLimitPrice((prevPrice) => ({
          ...prevPrice,
          error: true,
          helperText:
            'Limit price must be less than or equal to stop price for sell orders',
        }));
        return false;
      } else if (
        orderSideRef.current === '0' &&
        parseFloat(stopPrice.value) >= parseFloat(limitPrice.value)
      ) {
        setLimitPrice((prevPrice) => ({
          ...prevPrice,
          error: true,
          helperText:
            'Limit price must be greater than or equal to stop price for buy orders',
        }));
        return false;
      }
    }
    return true;
  };

  const orderConvertor = (clientOrderId) => {
    return {
      trader_account: selectedAccount.value,
      exchange_name: symbol.value.split('\\')[0], // Use symbol.value
      contract_name: symbol.value.split('\\')[1],
      contract_date: symbol.value.split('\\')[2],
      order_type: parseInt(selectedOrderType.value, 10),
      order_side: parseInt(orderSideRef.current, 10),
      lots: parseInt(quantity.value, 10),
      price1: limitIds.includes(selectedOrderType.value)
        ? parseFloat(limitPrice.value)
        : null,
      price2: stopIds.includes(selectedOrderType.value)
        ? parseFloat(stopPrice.value)
        : null,
      open_close: parseInt(openClose.value, 10),
      hedge_flag: parseInt(hedgeFlag.value, 10),
      client_order_id: clientOrderId,
      is_fixed_price: false,
      remarks: comment,
    };
  };

  const onBuy = () => {
    orderSideRef.current = '0';
    toOrderRef.current = formNewOrder();
    setIsOpen(false);
    titleRef.current = 'New Order';
    setNewActionType('New');

    // Re-open the dialog after a slight delay to ensure content updates
    setTimeout(() => {
      setIsOpen(true);
    }, 0); // Small delay to allow the dialog to close and reopen
  };

  const onSell = () => {
    orderSideRef.current = '1';
    toOrderRef.current = formNewOrder();
    setIsOpen(false);
    titleRef.current = 'New Order';
    setNewActionType('New');

    // Re-open the dialog after a slight delay to ensure content updates
    setTimeout(() => {
      setIsOpen(true);
    }, 0); // Small delay to allow the dialog to close and reopen
  };

  const formNewOrder = () => {
    // Perform validation
    const isSymbolValid = validateSymbol();
    const isLimitPriceValid = validateLimitPrice();
    const isStopPriceValid = validateStopPrice();
    const isStopLimitOrderValid = validateStopLimitOrder();

    // If any validation fails, stop the submission
    if (
      !isSymbolValid ||
      !isLimitPriceValid ||
      !isStopPriceValid ||
      !isStopLimitOrderValid
    ) {
      return;
    }

    const clientOrderId = generateClientOrderId();
    return orderConvertor(clientOrderId);
  };

  const handleOrderSubmission = (orderInfo) => {
    const clientOrderId = orderInfo.client_order_id;
    orderSideRef.current = orderInfo.order_side;
    // Perform validation
    const isSymbolValid = validateSymbol();
    const isLimitPriceValid = validateLimitPrice();
    const isStopPriceValid = validateStopPrice();
    const isStopLimitOrderValid = validateStopLimitOrder();

    // If any validation fails, stop the submission
    if (
      !isSymbolValid ||
      !isLimitPriceValid ||
      !isStopPriceValid ||
      !isStopLimitOrderValid
    ) {
      return;
    }

    const newOrder = orderConvertor(clientOrderId);
    // Submit the order
    addOrder(webSocketInstance, token, newOrder);

    // TODO Store the client_order_id to track the status
    setOrderStatus((prev) => ({
      ...prev,
      [clientOrderId]: { status: 'pending', ...newOrder },
    }));
  };

  const handleOrderAmend = (orderInfo) => {
    const clientOrderId = orderInfo.client_order_id;
    orderSideRef.current = orderInfo.order_side;
    // Perform validation
    const isSymbolValid = validateSymbol();
    const isLimitPriceValid = validateLimitPrice();
    const isStopPriceValid = validateStopPrice();
    const isStopLimitOrderValid = validateStopLimitOrder();

    // If any validation fails, stop the submission
    if (
      !isSymbolValid ||
      !isLimitPriceValid ||
      !isStopPriceValid ||
      !isStopLimitOrderValid
    ) {
      return;
    }

    const newAmendOrder = orderConvertor(clientOrderId);
    amendOrder(webSocketInstance, token, newAmendOrder);

    // Store the client_order_id to track the status
    setOrderStatus((prev) => ({
      ...prev,
      [clientOrderId]: { status: 'pending', ...newAmendOrder },
    }));
  };

  const handleCancel = (orderInfo) => {
    const clientOrderId = orderInfo.client_order_id;
    cancelOrders(webSocketInstance, [orderInfo.client_order_id]);
    // Store the client_order_id to track the status
    setOrderStatus((prev) => ({
      ...prev,
      [clientOrderId]: { status: 'pending', ...orderInfo },
    }));
  };

  useEffect(() => {
    if (toConfirm) {
      if (actionType === 'Amend') {
        handleOrderAmend(orderInfo);
      } else if (actionType === 'Delete') {
        handleCancel(orderInfo);
      } else if (actionType === 'New') {
        handleOrderSubmission(orderInfo);
      } else {
        console.log('error');
      }
    }
  }, [toConfirm]);

  return (
    <Box
      sx={{
        padding: theme.spacing(1),
        width: '100%',
        height: '100%', // Full height to manage overflow within this box
        maxHeight: '100%', // Ensure no overflow beyond parent
        overflowY: 'auto', // Allow vertical scrolling
        overflowX: 'hidden', // Prevent horizontal scrolling
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in Chrome, Safari, and Opera
        },
      }}
    >
      {/* Two-column layout for Client Order ID and Exchange Order ID */}
      {(isDisabled || isAmend) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(4),
          }}
        >
          {/* Client Order ID */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6">
              Client Order ID: {clientOrderId.value}
            </Typography>
          </Box>

          {/* Exchange Order ID */}
          <Box sx={{ flex: 1, textAlign: 'right' }}>
            <Typography variant="h6">
              Exchange Order ID: {exchangeOrderId.value}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        }}
      >
        <Dropdown
          label="Symbol"
          items={[]}
          value={symbol.value}
          onChange={(e) =>
            setSymbol((prevSymbol) => ({
              ...prevSymbol, // Preserve error and helperText
              value: e.target.value, // Update only the value
            }))
          }
          error={symbol.error} // Boolean to show error state (true or false)
          helperText={symbol.helperText} // Show helper text if there's an error message
          sx={{ flex: 1 }}
          disabled={isDisabled || isAmend} // Disable in Amend or Delete mode
        />
        <Box sx={{ marginLeft: theme.spacing(2), flex: 1 }}>
          <CustomGrid config={tradeInfoFields} data={priceInfoRef.current} />
        </Box>
      </Box>

      <Dropdown
        label="Select Account"
        items={accs}
        value={selectedAccount.value}
        onChange={(e) => handleChanges(e, setSelectedAccount)}
        sx={{ marginBottom: theme.spacing(2) }}
        disabled={isDisabled || isAmend} // Disable in Amend or Delete mode
      />

      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2), // Add space between the two fields
          marginBottom: theme.spacing(2),
        }}
      >
        <TextField
          label="Quantity"
          value={quantity.value}
          onChange={(e) => handleChanges(e, setQuantity, greaterThanZero)}
          fullWidth
          error={quantity.error}
          helperText={quantity.helperText}
          disabled={isDisabled} // Disable in Amend or Delete mode
        />

        <Dropdown
          label="Order Type"
          items={processedEnums.orderTypes}
          value={selectedOrderType.value}
          onChange={(e) => handleChanges(e, setSelectedOrderType)}
          fullWidth
          disabled={isDisabled || isAmend} // Disable in Amend or Delete mode
        />

        <Dropdown
          label="Time in Force"
          items={timeInForceList}
          value={timeInForce.value}
          onChange={(e) => handleChanges(e, setTimeInForce)}
          disabled={isDisabled || isAmend} // Disable in Amend or Delete mode
        />
      </Box>

      {/* Conditionally render Limit Price field */}
      {limitIds.includes(selectedOrderType.value) && (
        <TextField
          label="Limit Price"
          value={limitPrice.value}
          onChange={(e) => handleChanges(e, setLimitPrice, validateNumber)}
          fullWidth
          error={limitPrice.error}
          helperText={limitPrice.helperText}
          sx={{ marginBottom: theme.spacing(2) }}
          disabled={isDisabled} // Disable Delete mode
        />
      )}

      {/* Conditionally render stop Price field */}
      {stopIds.includes(selectedOrderType.value) && (
        <TextField
          label="Stop Price"
          value={stopPrice.value}
          onChange={(e) => handleChanges(e, setStopPrice, validateNumber)}
          fullWidth
          error={stopPrice.error}
          helperText={stopPrice.helperText}
          sx={{ marginBottom: theme.spacing(2) }}
          disabled={isDisabled}
        />
      )}

      {stopLimitIds.includes(selectedOrderType.value) && (
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <TextField
            label="Stop Price"
            value={stopPrice.value}
            onChange={(e) => handleChanges(e, setStopPrice, validateNumber)}
            error={stopPrice.error}
            helperText={stopPrice.helperText}
            fullWidth
            disabled={isDisabled}
          />
          <TextField
            label="Limit Price"
            value={limitPrice.value}
            onChange={(e) => handleChanges(e, setLimitPrice, validateNumber)}
            error={limitPrice.error}
            helperText={limitPrice.helperText}
            fullWidth
            disabled={isDisabled}
          />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2), // Add space between the two fields
          marginBottom: theme.spacing(2),
        }}
      >
        <Dropdown
          label="O/C"
          items={processedEnums.openCloseTypes}
          value={openClose.value}
          onChange={(e) => handleChanges(e, setOpenClose)}
          disabled={isDisabled || isAmend} // Disable in Amend or Delete mode
        />

        <Dropdown
          label="H/S"
          items={processedEnums.hedgeFlags}
          value={hedgeFlag.value}
          onChange={(e) => handleChanges(e, setHedgeFlag)}
          disabled={isDisabled || isAmend} // Disable in Amend or Delete mode
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        {isDisabled || isAmend || isNew ? (
          <Button
            variant="contained"
            sx={{
              width: '100%',
              '&.Mui-disabled': {
                backgroundColor:
                  String(orderInfo.order_side) === '0'
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.red, // Keep the same background color when disabled
                color: '#fff', // Keep the same text color when disabled
                opacity: 0.6, // Optionally reduce opacity to indicate disabled state
              },
            }}
            disabled={true}
          >
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
              {String(orderInfo.order_side) === '0' ? 'BUY' : 'SELL'}
            </Typography>
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={() => onBuy()}
              sx={{
                flexDirection: 'column', // Stack the price info above the label
                width: '48%',
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'darkblue',
                },
              }}
            >
              {priceInfoRef.current.askInfo && (
                <Typography
                  variant="caption"
                  sx={{ fontSize: '0.6rem', color: '#ccc' }}
                >
                  {priceInfoRef.current.askInfo}
                </Typography>
              )}
              <Typography
                variant="button"
                sx={{ fontSize: '1rem', color: '#fff', fontWeight: 'bold' }}
              >
                BUY
              </Typography>
            </Button>
            <Button
              variant="contained"
              onClick={() => onSell()}
              sx={{
                flexDirection: 'column', // Stack the price info above the label
                width: '48%',
                backgroundColor: theme.palette.secondary.red,
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              {priceInfoRef.current.bidInfo && (
                <Typography
                  variant="caption"
                  sx={{ fontSize: '0.6rem', color: '#ccc' }}
                >
                  {priceInfoRef.current.bidInfo}
                </Typography>
              )}
              <Typography
                variant="button"
                sx={{ fontSize: '1rem', color: '#fff', fontWeight: 'bold' }}
              >
                SELL
              </Typography>
            </Button>
          </>
        )}
      </Box>

      <TextField
        label="Comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        fullWidth
        sx={{ marginBottom: theme.spacing(1) }}
        disabled={isDisabled}
      />

      {/* Accordion for Advanced Features */}
      {!(isDisabled || isAmend) && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="advanced-features-content"
            id="advanced-features-header"
            sx={{
              display: 'flex',
              alignItems: 'center', // Align items centrally
              justifyContent: 'space-between',
              padding: 0,
            }}
          >
            <Typography
              variant="body2" // Smaller font size
              sx={{
                textAlign: 'right',
                flexGrow: 1,
                letterSpacing: '0.05em', // Slight letter spacing
                transition: 'color 0.3s ease, font-size 0.3s ease', // Smooth transition for color and font size
                '&:hover': {
                  color: theme.palette.secondary.light, // Lighter color on hover
                  cursor: 'pointer', // Pointer cursor on hover
                },
              }}
            >
              Advanced
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginTop: theme.spacing(1), padding: 0 }}>
            <TextField
              label="Stop Loss"
              value={stopLoss}
              onChange={(e) => setStopLoss(e.target.checked)}
              fullWidth
              sx={{ marginBottom: theme.spacing(2) }}
            />
            <TextField
              label="Take Profit"
              value={takeProfit}
              onChange={(e) => setTakeProfit(e.target.checked)}
              fullWidth
              sx={{ marginBottom: theme.spacing(2) }}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <OrderDialog
        isOpen={isOpen}
        amendOrderRef={toOrderRef}
        actionType={newActionType}
        title={titleRef.current}
      />
    </Box>
  );
}

export default OrderEntryPanel;
