import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { createRoot } from 'react-dom/client';
import App from '../App';

// Create the context
const WindowContext = createContext();

// // Custom hook to access the window context
// export const useWindowContext = () => useContext(WindowContext);

// // Utility to initialize IndexedDB
// const initDB = async () => {
//   return await openDB('windowsDB', 1, {
//     upgrade(db) {
//       if (!db.objectStoreNames.contains('windows')) {
//         db.createObjectStore('windows', { keyPath: 'id' });
//       }
//     },
//   });
// };

// // Save window state to IndexedDB
// const saveWindowStateToDB = async (id, windowState) => {
//   const db = await initDB();
//   await db.put('windows', { id, ...windowState });
// };

// // Retrieve window state from IndexedDB
// const getWindowStateFromDB = async (id) => {
//   const db = await initDB();
//   return await db.get('windows', id);
// };

// Utility to inject styles into the new window
const injectStyles = (newWindow, styleSheets) => {
  const head = newWindow.document.head;

  styleSheets.forEach((styleSheet) => {
    if (styleSheet.href) {
      // Only process stylesheets with a href attribute (external styles)
      const linkEl = newWindow.document.createElement('link');
      linkEl.rel = 'stylesheet';
      linkEl.href = styleSheet.href;
      head.appendChild(linkEl);
    } else {
      // Inline styles (for style tags without href)
      const styleEl = newWindow.document.createElement('style');
      styleEl.textContent = Array.from(styleSheet.cssRules)
        .map((rule) => rule.cssText)
        .join('');
      head.appendChild(styleEl);
    }
  });
};

// Provider component to manage window state globally
export const WindowProvider = ({ children }) => {
  const [windows, setWindows] = useState({}); // Store windows as an object

  // Function to open a new window and render a component inside it
  const openWindow = useCallback(
    async (id, Component, props = {}, options = {}) => {
      const {
        title = 'New Window',
        width = 800,
        height = 600,
        x = 100,
        y = 100,
        usePortal = false,
        renderCallback,
        additionalContent,
        onOpen,
        onClose,
      } = options;

      // Open a new window
      const newWindow = window.open(
        '',
        id,
        `width=${width},height=${height},left=${x},top=${y},toolbar=no,location=no,status=no,menubar=no,scrollbars=no`
      );

      // Dynamically set the window title
      newWindow.document.title = title;

      const containerEl = newWindow.document.createElement('div');
      newWindow.document.body.appendChild(containerEl);

      // Optionally inject additional content before rendering the component
      if (additionalContent) {
        const additionalEl = newWindow.document.createElement('div');
        additionalEl.innerHTML = additionalContent;
        newWindow.document.body.appendChild(additionalEl);
      }

      // Inject styles from the parent window into the new window
      injectStyles(newWindow, Array.from(document.styleSheets));

      // Handle lifecycle: trigger `onOpen` when window is created
      if (onOpen) onOpen(newWindow);

      // **Modified**: Store the root in window reference if not already created
      let root;
      if (windows[id]?.root) {
        root = windows[id].root;
      } else {
        root = createRoot(containerEl); // Create the root for the first time
      }

      // Default rendering of the component using createRoot (React 18+)
      const defaultRender = () => {
        // Render App without routing in the ne
        // Render the App component without routing in the new window
        // Render the App component and the child Component in the same render call
        root.render(
          <App isMainWindow={false}>
            <Component {...props} />{' '}
            {/* Render the child component inside App */}
          </App>
        );
      };

      // If `usePortal` is true, allow manual `ReactDOM.createPortal`
      if (usePortal && renderCallback) {
        // Invoke the custom render callback, passing the containerEl
        renderCallback(newWindow, containerEl);
      } else {
        // Render the component in the new window after styles are injected
        setTimeout(defaultRender, 100); // Adjust this delay if necessary
      }

      // Cleanup: handle window close event and lifecycle cleanup
      const cleanup = () => {
        root.unmount(); // Unmount the component in the new window
        newWindow.close();
        if (onClose) onClose();
        setWindows((prevWindows) => {
          const { [id]: _, ...remainingWindows } = prevWindows; // Remove the closed window
          return remainingWindows;
        });

        // Save the final state to IndexedDB when closing
        // const windowState = {
        //   width: newWindow.innerWidth,
        //   height: newWindow.innerHeight,
        //   x: newWindow.screenX,
        //   y: newWindow.screenY,
        // };
        // saveWindowStateToDB(id, windowState);
      };

      // Automatically clean up when the window is closed
      newWindow.onbeforeunload = cleanup;

      // Store the window reference as an object, keyed by window ID
      const windowRef = { newWindow, root, cleanup };
      console.log(id);
      setWindows((prevWindows) => ({
        ...prevWindows,
        [id]: windowRef, // Store by unique ID
      }));

      // Return the window, container, and cleanup function for manual control
      return windowRef;
    },
    []
  );

  // Function to close a specific window by its ID
  const closeSingleWindow = useCallback(
    (id) => {
      const windowRef = windows[id];
      if (windowRef && windowRef.cleanup) {
        windowRef.cleanup();
      }
    },
    [windows]
  );

  // Function to close all windows, with an option to exclude the main window
  const closeAllWindows = useCallback(
    (includeMainWindow = true) => {
      Object.values(windows).forEach(({ newWindow, cleanup }) => {
        // Only close the sub-windows, not the main window if includeMainWindow is false
        if (includeMainWindow || newWindow !== window) {
          cleanup(); // Clean up each window
        }
      });

      // If includeMainWindow is true, close the main window as well
      if (includeMainWindow) {
        window.close(); // This will attempt to close the main window
      }

      setWindows({}); // Clear the windows state
    },
    [windows]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', closeAllWindows);

    return () => {
      window.removeEventListener('beforeunload', closeAllWindows);
    };
  }, [closeAllWindows]);

  // Provide functions and state to children
  return (
    <WindowContext.Provider
      value={{ openWindow, closeSingleWindow, closeAllWindows }}
    >
      {children}
    </WindowContext.Provider>
  );
};

export const useWindow = () => useContext(WindowContext);
