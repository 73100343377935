import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useError } from '../errorHandler/ErrorContext';
import {
  tablesChannel,
  tabsChannel,
  workspacesChannel,
  chartsChannel,
} from './channelConstants';

const BroadcastChannelContext = createContext(null);

export const BroadcastChannelProvider = ({ children }) => {
  const channels = useRef({});
  const { handleError } = useError();
  const [channelsReady, setChannelsReady] = useState(false);

  useEffect(() => {
    // Initialize channels with their respective identifiers
    const channelNames = [
      tablesChannel,
      tabsChannel,
      chartsChannel,
      workspacesChannel,
    ];

    channelNames.forEach((channelName) => {
      const channel = new BroadcastChannel(channelName);
      channels.current[channelName] = {
        bc: channel,
        callbacks: [],
      };

      // Generic handler for incoming messages
      channel.onmessage = (event) => {
        channels.current[channelName].callbacks.forEach((callback) => {
          callback(event.data); // Pass the entire data object to each callback
        });
      };
    });

    setChannelsReady(true);

    return () => {
      // Close all channels when the component unmounts
      Object.values(channels.current).forEach((c) => c.bc.close());
    };
  }, []);

  const postMessage = (channelName, payload) => {
    const channel = channels.current[channelName];
    if (channel) {
      const message = {
        payload,
        timestamp: new Date(),
      };
      channel.bc.postMessage(message);
    } else {
      handleError(2001, channelName);
    }
  };

  const subscribeToChannel = (channelName, callback) => {
    const channel = channels.current[channelName];
    if (channel) {
      channel.callbacks.push(callback);

      // Provide a way to unsubscribe
      return () => {
        const index = channel.callbacks.indexOf(callback);
        if (index !== -1) {
          channel.callbacks.splice(index, 1);
        }
      };
    } else {
      if (channels.current) {
        handleError(2001, channelName);
      }
      return () => {}; // No-op function if the channel doesn't exist
    }
  };

  return (
    <BroadcastChannelContext.Provider
      value={{ postMessage, subscribeToChannel, channelsReady }}
    >
      {children}
    </BroadcastChannelContext.Provider>
  );
};

export const useBroadcastChannel = () => useContext(BroadcastChannelContext);
