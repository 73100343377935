import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import { useWebSocket } from './WebSocketContext'; // Assuming you have WebSocketContext for WebSocket connection
import { subscribeToOrders } from '../services/websocket/orders/OrderService'; // Import the subscribeToOrders function
import { initOrders } from '../services/websocket/orders/initOrders';

// Create the context
const OrderContext = createContext();

// Create a custom hook to use the OrderContext
export const useOrders = () => useContext(OrderContext);

// Provider component
export const OrderProvider = ({ children }) => {
  const { webSocketInstance, isWebSocketConnected } = useWebSocket(); // Assuming you have these from WebSocketContext
  const [orders, setOrders] = useState({
    accumulatedOrders: {},
    newOrder: null,
    updateOrder: null,
  });
  const [isOrderInitializing, setOrderIsInitializing] = useState(true);

  // Refs
  const batchOrdersRef = useRef({ orders: [], timeout: null });
  const isOrderInitializingRef = useRef(isOrderInitializing);
  const [toDeleteOrders, setTodeleteOrders] = useState([]);

  // Update the ref whenever the state changes
  useEffect(() => {
    isOrderInitializingRef.current = isOrderInitializing;
  }, [isOrderInitializing]);

  useEffect(() => {
    if (webSocketInstance && isWebSocketConnected) {
      let initializationTimeout;
      let isProcessingOrders = false;

      const handleIncomingOrder = async (order) => {
        clearTimeout(initializationTimeout); // Clear the timeout if any order is received
        if (isOrderInitializingRef.current) {
          isProcessingOrders = true;
          const processedOrders = await initOrders(order, batchOrdersRef);
          isProcessingOrders = false;
          if (processedOrders) {
            setOrderIsInitializing(false); // Mark initialization as complete
            setOrders({
              accumulatedOrders: {
                ...processedOrders, // Add batched processed orders
              },
              newOrder: null, // No new order during initialization
              updateOrder: null, // No update order during initialization
            });
          }
        } else {
          // Real-time order processing
          setOrders((prevOrders) => {
            const isExistingOrder =
              !!prevOrders.accumulatedOrders[order.client_order_id];
            return {
              ...prevOrders,
              accumulatedOrders: {
                ...prevOrders.accumulatedOrders,
                [order.client_order_id]: order,
              },
              newOrder: isExistingOrder ? null : order,
              updateOrder: isExistingOrder ? order : null,
            };
          });
        }
      };

      const unsubscribeOrders = subscribeToOrders(
        webSocketInstance,
        handleIncomingOrder
      );

      // Set a timeout for initialization
      initializationTimeout = setTimeout(() => {
        if (!isProcessingOrders && isOrderInitializingRef.current) {
          setOrderIsInitializing(false); // Mark initialization as complete if no orders are processing
          setOrders({
            accumulatedOrders: {}, // No orders received during initialization
            newOrder: null,
            updateOrder: null,
          });
        }
      }, 1000);

      return () => {
        unsubscribeOrders();
        setOrderIsInitializing(true);
        clearTimeout(initializationTimeout); // Clean up the timeout on component unmount
      };
    }
  }, [webSocketInstance, isWebSocketConnected]);

  return (
    <OrderContext.Provider
      value={{ orders, isOrderInitializing, toDeleteOrders, setTodeleteOrders }}
    >
      {children}
    </OrderContext.Provider>
  );
};
