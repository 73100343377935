// src/services/websocket/initWebSocketService.js
import { DirectWebSocket } from './DirectWebSocket';
import { SharedWorkerSocket } from './SharedWorkerSocket';
import globalConfig from '../../config/config';

const sharedWorkerPath = '/sharedWorker.js';
const isSharedWorkerSupported = !!window.SharedWorker;

// Function to initialize the WebSocket service based on the current configuration
function initializeWebSocketService(token) {
  if (isSharedWorkerSupported) {
    return new SharedWorkerSocket(sharedWorkerPath, token);
  } else {
    return new DirectWebSocket(globalConfig.getConfig().webSocketURL);
  }
}

export default initializeWebSocketService;
