import globalConfig from '../../config/config';

export class SharedWorkerSocket {
  constructor(sharedWorkerPath, token) {
    this.sharedWorker = new SharedWorker(sharedWorkerPath);
    this.listeners = {}; // Holds listeners for different message types
    this.metricsCallback = null;
    this.token = token; // Store the token
    this.webSocketURL = globalConfig.webSocketURL;

    this.sharedWorker.port.start();
    console.log('WEBSOCKET_URL:', globalConfig.webSocketURL);

    // Initialize the WebSocket connection within the shared worker
    this.sharedWorker.port.postMessage({
      type: 'INIT',
      result: { url: globalConfig.webSocketURL, token: this.token }, // Include the token,
    });
  }

  // Function to return the current WebSocket URL
  getWebSocketURL() {
    return this.webSocketURL;
  }

  // Update WebSocket URL as needed
  updateWebSocketURL(newURL) {
    this.webSocketURL = newURL;
    this.sharedWorker.port.postMessage({
      type: 'UPDATE_URL',
      result: { url: this.webSocketURL },
    });
  }

  clearMetricsCallback() {
    this.metricsCallback = null; // Or however you need to signify no callback should be called
  }

  // Add to SharedWorkerSocket class
  setMetricsCallback(callback) {
    this.addListener('METRICS', callback);
    // Assuming you have a way to receive messages that include metrics,
    // you could use this callback when such a message is received.
  }

  addListener(messageType, callback) {
    if (!this.listeners[messageType]) {
      this.listeners[messageType] = [];
    }
    this.listeners[messageType].push(callback);
  }

  removeListener(messageType, callback) {
    if (this.listeners[messageType]) {
      this.listeners[messageType] = this.listeners[messageType].filter(
        (listener) => listener !== callback
      );
    }
  }

  send(result) {
    // Send a message to the shared worker to be transmitted over the WebSocket
    this.sharedWorker.port.postMessage({ type: 'SEND', result });
  }

  requestMetrics() {
    // Request the current WebSocket connection metrics from the shared worker
    this.sharedWorker.port.postMessage({ type: 'REQUEST_METRICS' });
  }

  disconnect() {
    // Send a message to the shared worker to close the WebSocket connection
    this.sharedWorker.port.postMessage({ type: 'DISCONNECT' });
  }
}
