import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core style
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Theme style
import { useTable } from '../../contexts/TableContext';
import { useWorkspaces } from '../../contexts/WorkspacesContext';
import { useTheme } from '@mui/material/styles';
import './dark.css';
import './light.css';
import useDataSaver from '../../db/indexedDB/useDataSaver';
import { tableSaver } from '../../db/indexedDB/DBsaverMapper';
import '../../styles/updownFlashAnimation.css';
import './style.css';
import { getContextMenuItems } from './gridUtils';

const VTable = (props) => {
  const { tableId, insertRows, updateRows, deleteRows, config, dynConfig } =
    props;
  // Memoize the columns to ensure it only changes when `config.cols` changes
  const columns = useMemo(() => config?.cols || [], [config?.cols]);
  const autoSizeMode = config?.autoSizeMode || 'sizeColumnsToContentStrategy';
  const autoSave = config?.autoSave !== undefined ? config.autoSave : true;
  const contextMenuConfig = config?.contextMenuConfig || [];
  const paginationPageSize = config?.paginationPageSize || 200;
  const [isPagination, setIsPagination] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false); // Track grid ready state
  const { currentWorkspace } = useWorkspaces();
  const {
    changeCellValue,
    setSelectedRow,
    setTables,
    setSelectedTableRow,
    tables,
    initialTableState,
  } = useTable();
  const gridRef = useRef(null);

  const getRowId = (columns) => {
    const findRowId = (cols) => {
      for (let col of cols) {
        if (col.isRowId) {
          return col.field;
        }

        // If the column has children, recursively search in the children
        if (col.children && col.children.length > 0) {
          const childResult = findRowId(col.children);
          if (childResult) {
            return childResult;
          }
        }
      }
      return undefined;
    };
    // Return the result of the findRowId function
    return findRowId(columns);
  };

  const cleanColDefs = (columns) => {
    return columns.map((col) => {
      // eslint-disable-next-line no-unused-vars
      const { isRowId, ...rest } = col;
      return rest;
    });
  };

  const cleanColumns = useMemo(() => cleanColDefs(columns), [columns]);
  const rowId = useMemo(() => {
    return getRowId(columns);
  }, [columns]);

  const theme = useTheme(); // Access MUI theme
  const gridClassName =
    theme.palette.mode === 'dark'
      ? 'ag-theme-custom-dark'
      : 'ag-theme-custom-light';
  // Using useMemo to define the grid style with dependencies on theme changes
  const gridStyle = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      // '--ag-primary-color': theme.palette.primary.main,
      // '--ag-secondary-color': theme.palette.secondary.main,
      '--ag-font-family': theme.typography.fontFamily,
      // fontSize: theme.typography.body1.fontSize, // Using body1's font size as an example
      // color: theme.palette.text.primary, // Apply text color
    }),
    [theme]
  );

  const autoSizeAll = () => {
    if (gridRef.current) {
      const gridApi = gridRef.current;
      const allColumnIds = gridApi
        .getAllGridColumns()
        .map((col) => col.getColId());

      if (autoSizeMode === 'sizeColumnsToContentStrategy') {
        if (allColumnIds.length) {
          // Only auto-size if there are columns
          gridApi.autoSizeColumns(allColumnIds, false);
        }
      } else if (autoSizeMode === 'sizeColumnsToFitGridStrategy') {
        gridApi.sizeColumnsToFit();
      }
    }
  };

  // Settings for the grid
  const gridOptions = useMemo(
    () => ({
      paginationPageSize: 200, // Number of rows per page
      pagination: isPagination, // Enable pagination
      paginationPageSizeSelector: [100, 200, 500], // Ensure the page size is in this array
      suppressPropertyNamesCheck: true, // Suppress validation to allow custom properties

      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
      },
      // getRowId callback: Ensure it returns a string
      getRowId: (params) => {
        const id = params.data[rowId]; // Make sure 'rowId' points to the correct field in your data
        return id; // Ensure the ID is a string
      },
      // Updated selection configuration using the new v32.2+ API
      selection: {
        mode: 'multiRow', // Use 'singleRow' for single-row selection
        enableClickSelection: true, // Allow row selection by clicking on the row
        checkboxes: false, // Disable checkboxes for row selection
        headerCheckbox: false,
      },
    }),
    [isPagination]
  );

  // Save grid state function
  const saveGridState = useCallback(() => {
    if (!gridRef.current) return;

    const gridApi = gridRef.current;
    const columnState = gridApi.getColumnState();
    const savedState = { columnState };

    setTables((prevTables) => {
      const existingTable = prevTables[tableId];
      return {
        ...prevTables,
        [tableId]: existingTable
          ? {
              ...existingTable,
              tableState: savedState,
            }
          : initialTableState(tableId, savedState),
      };
    });
  }, [tableId, setTables]);

  // Effect to handle column state changes
  useEffect(() => {
    if (!gridRef.current || gridRef.current.isDestroyed()) return;

    const gridApi = gridRef.current;
    // Add listeners for column state changes
    const handleColumnStateChange = () => {
      saveGridState();
    };

    gridApi.addEventListener('columnMoved', handleColumnStateChange);
    gridApi.addEventListener('columnPinned', handleColumnStateChange);
    gridApi.addEventListener('columnVisible', handleColumnStateChange);
    gridApi.addEventListener('columnResized', handleColumnStateChange);

    // Cleanup event listeners when component unmounts
    return () => {
      if (!gridRef.current.isDestroyed()) {
        gridApi.removeEventListener('columnMoved', handleColumnStateChange);
        gridApi.removeEventListener('columnPinned', handleColumnStateChange);
        gridApi.removeEventListener('columnVisible', handleColumnStateChange);
        gridApi.removeEventListener('columnResized', handleColumnStateChange);
      }
    };
  }, [gridRef.current]);

  // // // Use `onGridDestroyed` if `onGridPreDestroy` doesn't trigger
  // const handleGridPreDestroyed = () => {
  //   console.log('Grid destroyed');
  //   // potential issue: when table (tab) is deleted, it will update setTabs again and make it insert into index db again.
  //   //https://tradexsys.atlassian.net/browse/DEV-2287
  //   // saveGridState(); // Attempt to save grid state
  // };

  const handleGridReady = (params) => {
    gridRef.current = params.api;
    setIsGridReady(true); // Indicate that the grid is ready

    // Ensure that the grid API exists before calling API methods
    if (gridRef.current) {
      // Show the loading overlay initially
      gridRef.current.setGridOption('loading', true);

      // If pagination is enabled, go to the first page
      if (isPagination) {
        gridRef.current.paginationGoToPage(0);
      }
    }
  };

  useEffect(() => {
    if (gridRef.current) {
      const savedState = tables[tableId]?.tableState;
      if (savedState) {
        const { columnState } = savedState;
        const gridApi = gridRef.current;
        gridApi.applyColumnState({ state: columnState, applyOrder: true });
      } else {
        autoSizeAll();
      }
    }
  }, [tables[tableId], gridRef.current]);

  useEffect(() => {
    if (gridRef.current && deleteRows && deleteRows.length > 0) {
      const api = gridRef.current;
      // Apply the delete transaction to remove rows from the grid
      api.applyTransaction({ remove: deleteRows });
    }
  }, [deleteRows, gridRef.current]); // Runs when deleteRows or gridRef.current changes

  useEffect(() => {
    if (gridRef.current && isGridReady) {
      const api = gridRef.current;

      const transaction = {};

      if (insertRows && insertRows.length > 0) {
        transaction.add = insertRows;
      }

      if (Object.keys(transaction).length > 0) {
        api.applyTransaction(transaction);
        // Get the number of rows after applying the transaction

        const rowCount = api.getDisplayedRowCount();
        if (rowCount > paginationPageSize) {
          setIsPagination(true);
        }
      }
      api.setGridOption('loading', false);
      if (api.getDisplayedRowCount() <= 0) {
        api.showNoRowsOverlay(); // Show "No Rows" overlay if there's no data
      }
    }
  }, [insertRows, isGridReady, gridRef.current]);

  const trackedColumns = useMemo(() => {
    // Recursive function to flatten the columns structure
    const flattenColumns = (cols) => {
      return cols.reduce((acc, col) => {
        // Add the column itself if it has the 'trackOldValue' property
        if (col.trackOldValue) {
          acc.push(col.field);
        }

        // If the column has children, recursively process them
        if (col.children && col.children.length > 0) {
          acc.push(...flattenColumns(col.children));
        }

        return acc;
      }, []);
    };

    // Use the recursive function to get the tracked columns
    return flattenColumns(columns);
  }, [columns]);

  const updateOldValuesInNodes = () => {
    if (gridRef.current && trackedColumns.length > 0) {
      gridRef.current.forEachNode((node) => {
        if (!node.oldRowData) {
          node.oldRowData = {};
        }

        trackedColumns.forEach((field) => {
          const oldValue = node.oldRowData[field];
          const newValue = node.data[field];

          if (newValue !== oldValue) {
            node.oldRowData[field] = newValue;
          }
        });
      });
    }
  };

  const applyFlashingEffect = (result) => {
    if (result.update) {
      const cellCache = {};

      result.update.forEach((updatedNode) => {
        // Skip if there's no old data
        if (!updatedNode.oldRowData) return;

        Object.keys(updatedNode.oldRowData).forEach((field) => {
          const oldValue = updatedNode.oldRowData[field];
          const newValue = updatedNode.data[field];

          // Determine the flash class based on value comparison
          const flashClass =
            newValue > oldValue
              ? 'ag-flash-red'
              : newValue < oldValue
                ? 'ag-flash-green'
                : null;

          if (flashClass) {
            const rowIndex = updatedNode.rowIndex;
            const cellCacheKey = `${rowIndex}-${field}`;

            // Cache the cell element if not already cached
            if (!cellCache[cellCacheKey]) {
              cellCache[cellCacheKey] = document.querySelector(
                `.ag-row[row-index="${rowIndex}"] .ag-cell[col-id="${field}"]`
              );
            }

            const cellElement = cellCache[cellCacheKey];

            if (cellElement) {
              requestAnimationFrame(() => {
                // Make sure context menu is not affected by flashing
                if (!cellElement.classList.contains('ag-cell-focus')) {
                  cellElement.classList.add(flashClass);
                  setTimeout(() => {
                    cellElement.classList.remove(flashClass);
                  }, 1500); // Match this to your animation duration
                }
              });
            }
          }
        });
      });
    }
  };

  useEffect(() => {
    if (gridRef.current && updateRows && updateRows.length > 0) {
      const api = gridRef.current;

      // Before updating the grid with new data, store the current data as old values
      updateOldValuesInNodes();
      const result = api.applyTransaction({ update: updateRows });

      // Apply the flashing effect after updating the rows
      applyFlashingEffect(result);
    }
  }, [updateRows, gridRef.current]); // Runs when updateRows, or gridRef.current changes

  const onCellValueChanged = (params) => {
    const field = params.colDef.field; // This should work if params.field is undefined
    const newValue = params.newValue;
    const rowIdValue = params.data[rowId];

    changeCellValue(tableId, rowId, rowIdValue, field, newValue);
  };

  const onRowDragEnd = () => {
    // Ensure the grid reference exists before proceeding
    if (!gridRef.current) return;

    // Collect data of all displayed rows after filtering and sorting
    const allRowsData = gridRef.current
      .getRenderedNodes()
      .map((node) => node.data);

    // Update the tables state with the new order of rows for the specific tableId
    setTables((prevTables) => ({
      ...prevTables,
      [tableId]: {
        ...prevTables[tableId],
        rows: allRowsData, // Update the rows for the specific tableId
      },
    }));
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();

    if (selectedRows.length === 1) {
      // If only one row is selected, set it as the selected row
      setSelectedRow({ [currentWorkspace]: selectedRows[0] });
      setSelectedTableRow({ [tableId]: selectedRows[0] });
    }

    if (selectedRows.length > 1) {
      // If multiple rows are selected, set the last selected row as the current workspace
      setSelectedRow({
        [currentWorkspace]: selectedRows[selectedRows.length - 1],
      });
      setSelectedTableRow({ [tableId]: selectedRows[selectedRows.length - 1] });
    }
  };

  // Memoize the object, but return an empty object if autoSave is false
  const saveIdsObj = useMemo(
    () => (autoSave ? { [tableSaver]: [tableId] } : {}),
    [autoSave, tableId, tableSaver]
  );

  // Only call useDataSaver if autoSave is true
  useDataSaver(autoSave ? saveIdsObj : null);

  return (
    <div className={gridClassName} style={gridStyle}>
      <AgGridReact
        columnDefs={cleanColumns}
        gridOptions={gridOptions}
        immutableData={true}
        deltaRowDataMode={true}
        onGridReady={handleGridReady}
        rowDragManaged={true} // Manage row dragging within the grid
        rowDragEntireRow={true} // Enable dragging by any part of the row
        onRowDragEnd={onRowDragEnd}
        onCellValueChanged={onCellValueChanged}
        stopEditingWhenCellsLoseFocus={true}
        rowBuffer={10} // Increase row buffer for smoother scrolling
        suppressColumnVirtualisation={false} // Ensure this is not set to true
        onSelectionChanged={onSelectionChanged}
        getContextMenuItems={getContextMenuItems} // Use the imported function here
        context={{ contextMenuConfig }}
        // onGridPreDestroyed={handleGridPreDestroyed} // Save state just before grid is destroyed
      />
    </div>
  );
};

export default VTable;
