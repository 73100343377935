import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import { MetadataProvider } from './contexts/MetadataContext';
import AppRoutes from './routes/Routes';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { TableProvider } from './contexts/TableContext';
import { LayoutProvider } from './contexts/LayoutContext';
import { TabsProvider } from './contexts/TabsContext';
import { WorkspacesProvider } from './contexts/WorkspacesContext';
import { DBProvider } from './db/indexedDB/DBContext';
import { ErrorProvider } from './errorHandler/ErrorContext';
// ErrorModel is to handle logic error intentionly
import ErrorModal from './errorHandler/ErrorModal';
// ErrorBoundary is to handle unexpected error
import ErrorBoundary from './errorHandler/ErrorBoundary';
import { BroadcastChannelProvider } from './broadcastChannels/BroadcastChannelContext';
import { OptionChainProvider } from './contexts/OptionChainContext';
import { MarketDataProvider } from './contexts/MktDataContext';
import { OrderProvider } from './contexts/OrderContext';
import { WindowProvider } from './contexts/WindowContext ';
import './i18n';
import ThemedApp from './styles/themedApp';
import { TranslationProvider } from './contexts/translationContext';

function App({ isMainWindow = true, children }) {
  return (
    <TranslationProvider>
      <ThemedApp>
        <ErrorProvider>
          <ErrorBoundary>
            <BroadcastChannelProvider>
              <AuthProvider>
                <LayoutProvider>
                  <WebSocketProvider>
                    <MarketDataProvider>
                      <MetadataProvider>
                        <OrderProvider>
                          <DBProvider>
                            <WindowProvider>
                              <WorkspacesProvider>
                                <TableProvider>
                                  <TabsProvider>
                                    {/* OptionChainProvider parallel to TableProvider */}
                                    <OptionChainProvider>
                                      {isMainWindow ? <AppRoutes /> : children}
                                      {/* Render AppRoutes in main window, otherwise render children */}
                                      <ErrorModal />
                                    </OptionChainProvider>
                                  </TabsProvider>
                                </TableProvider>
                              </WorkspacesProvider>
                            </WindowProvider>
                          </DBProvider>
                        </OrderProvider>
                      </MetadataProvider>
                    </MarketDataProvider>
                  </WebSocketProvider>
                </LayoutProvider>
              </AuthProvider>
            </BroadcastChannelProvider>
          </ErrorBoundary>
        </ErrorProvider>
      </ThemedApp>
    </TranslationProvider>
  );
}

export default App;
