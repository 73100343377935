// schemaConfig.js
const tableSchema = {
  name: 'tables',
  keyPath: 'tableId',
  indexes: [
    { name: 'tableIdIndex', keyPath: 'tableId', unique: true },
    // Setting the index with the same keyPath as the primary key and marking it unique.
  ],
};

const tabsSchema = {
  name: 'tabs',
  keyPath: 'tabsId',
  indexes: [
    { name: 'tabsIdIndex', keyPath: 'tabsId', unique: true },
    // Similarly, indexing the primary key for tabs.
  ],
};

const workspacesSchema = {
  name: 'workspaces',
  keyPath: 'workspaceId',
  indexes: [
    { name: 'workspaceIdIndex', keyPath: 'workspaceId', unique: true },
    // And for workspaces, ensuring the primary key is indexed and unique.
  ],
};

const optionChainsSchema = {
  name: 'optionChains',
  keyPath: 'optionChainId',
  indexes: [
    { name: 'optionChainIdIndex', keyPath: 'optionChainId', unique: true },
    // And for optionChain, ensuring the primary key is indexed and unique.
  ],
};

// Exporting an array of all schemas
export const schemas = [
  tableSchema,
  tabsSchema,
  workspacesSchema,
  optionChainsSchema,
];

// After adding a new schema or making schema changes
// Start with a default version, this can be dynamically adjusted as per the application's need
export const dbVersion = 2;

// please udpdate version history here
// Version History (optional documentation)
// 1 - Initial version with 'tables' and 'tabs' stores
// 2 - Added 'optionChains' store
