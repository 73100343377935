import React, { useMemo } from 'react';
import VTable from '../tables/VTable';

const tableId = 'marketDepthTable'; // Unique table ID for Market Depth

const MarketDepth = () => {
  // Memoize rowData
  const rowData = useMemo(
    () => [
      { epiq: 1069, bids: 1069, price: 2139.5, asks: 1134, ltq: 29 },
      { epiq: 831, bids: 831, price: 2138.75, asks: 1240, ltq: '' },
      { epiq: 766, bids: 766, price: 2138.0, asks: 918, ltq: '' },
      // Add more rows as needed
    ],
    []
  );
  // Memoize column definitions
  const columnDefs = useMemo(
    () => [
      { field: 'epiq', headerName: 'EPIQ', width: 80 },
      {
        field: 'bids',
        headerName: 'Bids',
        cellClass: 'bids-cell',
        width: 100,
        cellStyle: { backgroundColor: '#98c8ff' },
      },
      {
        field: 'price',
        headerName: 'Price',
        cellStyle: { backgroundColor: '#f0f0f0' },
        width: 100,
      },
      {
        field: 'asks',
        headerName: 'Asks',
        cellClass: 'asks-cell',
        width: 100,
        cellStyle: { backgroundColor: '#ff9999' },
      },
      { field: 'ltq', headerName: 'LTQ', width: 80 },
    ],
    []
  );

  // Base config for the VTable (if applicable)
  const baseConfig = useMemo(
    () => ({
      autoSizeMode: 'sizeColumnsToContentStrategy',
      cols: columnDefs,
      autoSave: false, // Disable autosave unless needed
    }),
    [columnDefs]
  );

  return (
    <div>
      <h3>Market Depth</h3>
      <VTable tableId={tableId} insertRows={rowData} config={baseConfig} />
    </div>
  );
};

export default MarketDepth;
