import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useDB } from '../db/indexedDB/DBContext'; // Import DBContext utilities
import { useBroadcastChannel } from '../broadcastChannels/BroadcastChannelContext';
import { tablesChannel } from '../broadcastChannels/channelConstants';

const TableContext = createContext();

export const TableProvider = ({ children }) => {
  const { db, selectRows, upsertRows, deleteRows } = useDB(); // Using methods from DBContext
  const [activeTableId, setActiveTableId] = useState(null);
  const [tables, setTables] = useState({});
  const { postMessage, subscribeToChannel, channelsReady } =
    useBroadcastChannel();
  const activeTableIdRef = useRef(activeTableId); // Ref to keep track of activeTableIds
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedTableRow, setSelectedTableRow] = useState({});

  const initialTableState = useCallback(
    (tableId, tableState = {}) => ({
      tableId: tableId,
      tableState: tableState, // Use provided tableState or default to an empty object
      rows: [],
    }),
    []
  );

  // Load initial table states from IndexedDB when component mounts
  useEffect(() => {
    if (db && activeTableId) {
      selectRows('tables', [activeTableId]).then((fetchedArray) => {
        // Transform array into an object
        const fetchedTables = fetchedArray.reduce((acc, item) => {
          if (item && item.tableId) {
            acc[item.tableId] = item;
          }
          return acc;
        }, {});

        const newTables = { ...fetchedTables };

        if (!fetchedTables || Object.keys(fetchedTables).length === 0) {
          // fetchedTables is either undefined/null or an empty object
          newTables[activeTableId] = initialTableState(activeTableId);
        }

        setTables((prev) => ({ ...prev, ...newTables }));

        // Broadcast updated tables state
        postMessage(tablesChannel, newTables);
      });
    }
  }, [db, activeTableId, selectRows, postMessage]);

  // Update the ref whenever the activeTableIds state changes
  useEffect(() => {
    activeTableIdRef.current = activeTableId;
  }, [activeTableId]);

  useEffect(() => {
    if (channelsReady) {
      // Define the callback within the effect
      const tablesCallback = (data) => {
        console.log('Received data:', data);
        // Use ref's current value to access the most recent activeTableIds
        const relevantData = data.filter((item) =>
          activeTableIdRef.current.includes(item.tableId)
        );
        console.log('Relevant data for active tables:', relevantData);
        setTables((prev) => ({ ...prev, ...relevantData }));
      };
      const unsubscribe = subscribeToChannel(tablesChannel, tablesCallback);

      return () => unsubscribe(); // Cleanup on unmount
    }
  }, [subscribeToChannel, channelsReady]); // Notice activeTableIds is not a dependency

  // Function to load tables from IndexedDB based on tableIds
  const resumeTablesFromDB = async (tableIds) => {
    if (db && tableIds.length > 0) {
      try {
        // Fetch rows from IndexedDB for the given tableIds
        const fetchedArray = await selectRows('tables', tableIds);

        // Transform array into an object
        const fetchedTables = fetchedArray.reduce((acc, item) => {
          if (item && item.tableId) {
            acc[item.tableId] = item;
          }
          return acc;
        }, {});

        // Create new tables object and initialize missing tables
        const newTables = { ...fetchedTables };

        tableIds.forEach((id) => {
          if (!fetchedTables[id]) {
            // If the table doesn't exist, create an initial state
            newTables[id] = initialTableState(id);
          }
        });

        // Update tables state
        setTables((prev) => ({ ...prev, ...newTables }));

        // Broadcast updated tables state
        postMessage(tablesChannel, newTables);
      } catch (error) {
        console.error('Error resuming tables from DB:', error);
      }
    }
  };
  const removeTables = (tableIds) => {
    setTables((prevTables) => {
      const updatedTables = Object.keys(prevTables)
        .filter((tableId) => !tableIds.includes(tableId))
        .reduce((acc, tableId) => {
          acc[tableId] = prevTables[tableId];
          return acc;
        }, {});

      // Return a new object to ensure a new reference is created
      return { ...updatedTables };
    });

    deleteRows('tables', tableIds);
  };

  const updateTable = (tableIds, updates) => {
    const rows = tableIds
      .map((id) => updates[id])
      .filter((update) => update !== undefined);

    upsertRows('tables', rows);
  };

  const addRowsToTable = (tableId, newRows) => {
    setTables((prevTables = {}) => {
      // Check if prevTables or the specific tableId is not present
      if (!prevTables[tableId]) {
        // Initialize the table if it does not exist
        return {
          ...prevTables,
          [tableId]: {
            tableId: tableId,
            rows: newRows,
          },
        };
      } else {
        // Add rows to the existing table
        return {
          ...prevTables,
          [tableId]: {
            ...prevTables[tableId],
            rows: [...(prevTables[tableId].rows || []), ...newRows],
          },
        };
      }
    });
  };

  const updateTableSorting = (tableId, sortingUpdates) => {
    updateTable([tableId], {
      sorting: { ...(tables[tableId]?.sorting || {}), ...sortingUpdates },
    });
  };

  const updateRowOrder = (tableId, newOrder) => {
    updateTable([tableId], { rows: newOrder });
  };

  const changeCellValue = (tableId, rowId, rowIdValue, field, newValue) => {
    setTables((prevData) => ({
      ...prevData, // Spread the existing state to maintain other data
      [tableId]: {
        ...prevData[tableId], // Spread the current table data
        rows: prevData[tableId].rows.map((row) =>
          row[rowId].toString() === rowIdValue.toString()
            ? { ...row, [field]: newValue } // Update the specific field of the matching row
            : row
        ),
      },
    }));
  };

  return (
    <TableContext.Provider
      value={{
        tables,
        setTables,
        setActiveTableId,
        addRowsToTable,
        updateTableSorting,
        updateRowOrder,
        updateTable,
        changeCellValue,
        activeTableId,
        setSelectedRow,
        selectedRow,
        resumeTablesFromDB,
        selectedTableRow,
        setSelectedTableRow,
        initialTableState,
        removeTables,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const useTable = () => useContext(TableContext);
