import React, { createContext, useContext, useState, useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
import { loginUser, signOut as logoutFromAuth } from '../services/auth'; // Adjust the import path as needed

const AuthContext = createContext();
export const tokenKey = 'id_token';

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginId, setLoginId] = useState('');

  const login = async (email, password) => {
    try {
      setLoginId(email);
      const userDetails = await loginUser(email, password);
      const newToken = userDetails.token;
      // Optionally, fetch user details using the token
      localStorage.setItem(tokenKey, newToken);
      setToken(newToken);
      setCurrentUser(userDetails); // Simplified, usually you'd set user details
      setIsAuthenticated(true); // Update isAuthenticated state
    } catch (error) {
      setIsAuthenticated(false);
      console.error('Login error:', error);
      throw error; // Propagate the error to be handled in the login component
    }
  };

  const logout = () => {
    logoutFromAuth(); // Call your auth.js logout function
    setCurrentUser(null);
    setIsAuthenticated(false); // Update isAuthenticated state
    localStorage.removeItem(tokenKey);
    // Emit a custom event when logout happens
    const event = new CustomEvent('userLogout');
    window.dispatchEvent(event); // Broadcast the event globally
    // navigate('/login'); // Navigate back to the login page
  };

  // Memoize the value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      currentUser,
      isAuthenticated,
      login,
      logout,
      token,
      loginSuccess,
      setLoginSuccess,
      loginId,
    }),
    [currentUser, isAuthenticated, token, loginSuccess, loginId]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
