export const validateNumber = (
  value,
  { helperText = 'Please enter a valid number', condition } = {}
) => {
  if (isNaN(value)) {
    return {
      isValid: false,
      helperText: helperText || 'Please enter a valid number',
    };
  }

  if (condition && !condition(Number(value))) {
    return {
      isValid: false,
      helperText: helperText || 'Condition not met',
    };
  }

  return {
    isValid: true,
    helperText: '',
  };
};

export const greaterThanZero = (value) =>
  validateNumber(value, {
    helperText: 'Please enter a number greater than 0',
    condition: (num) => num > 0,
  });
