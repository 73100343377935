import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { loadConfigFromDB } from './config/loadConfig';
import App from './App';
// Import AG Grid Enterprise
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-067204}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TRADEX_SYSTEMS_PTE._LTD.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{tradex-opt}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{tradex-opt}_need_to_be_licensed___{tradex-opt}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{18_September_2025}____[v3]_[01]_MTc1ODE1MDAwMDAwMA==21c33f5ca1064d09782bc7f480866eb3'
);
const root = ReactDOM.createRoot(document.getElementById('root'));

const RootComponent = () => {
  const [configLoaded, setConfigLoaded] = useState(false);
  const [configError, setConfigError] = useState(null);

  useEffect(() => {
    loadConfigFromDB()
      .then(() => {
        setConfigLoaded(true);
      })
      .catch((error) => {
        setConfigError(error);
        setConfigLoaded(true); // Consider different handling based on your app's needs
      });
  }, []);

  if (!configLoaded) {
    console.log('Loading configuration...');
  }

  if (configError) {
    return <div>Error loading configuration: {configError.message}</div>; // Display error message
  }

  return (
    <BrowserRouter>
      <App isMainWindow={true} />
    </BrowserRouter>
  );
};

// React.StrictMode will render twice in development mode. it will impact to test mount and unmount issues.
root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
